/* .service-container {
    padding: 10px;
    margin: 10px;
    display: flex;
    justify-content: space-evenly;
} */
/* .links-container {
    padding: 10px;
    border: 1px solid;
    height: 450px;
    box-shadow: 12px 6px 4px 0px #0099ff;
    position: relative;
    top: 100px;
} */
.service-link {
    height: 40px;
    width: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.service-link a {
    text-decoration: none;
}
