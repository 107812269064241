.landing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin: 20px;
    height: 500px;
    margin-top: 80px;
    background-color: #013d7c;
    color: white;
    border-radius: 10px;
}
.company {
    color: #d1103f;
}
.landing h1 {
    font-size: 50px;
    line-height: 45px;
    margin-bottom: 20px;
}
.landing p {
    text-align: center;
    font-size: 22px;
    line-height: 33px;
    width: 70%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.landing button {
    width: 150px;
    padding: 10px;
    margin-top: 10px;
}
.landing button a{
    color: white;
    text-decoration: none;
}

@media (max-width: 576px) { 
    .landing {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .landing h1 {
        font-size: 24px;
        text-align: center;
    }
}

@media (max-width: 837px) { 
    .landing {
        display: flex;
        flex-direction: column;
    }
    .landing h1 {
        font-size: 24px;
        text-align: center;
    }
}