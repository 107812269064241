.about-container {
    display: flex;
    justify-content: space-around;
    margin: 30px;
    flex-direction: column;
}

.image-container {
    display: flex;
    justify-content: center;
}

.aboutus-image {
    height: 400px;
}

.about-text {
    font-size: 18px;
    padding: 100px;
    background-color: #013d7c;
    color: white;
    font-family: Bogle;
}

@media (max-width: 576px) { 
    .aboutus-image {
        height: 200px;
    }
    .about-container {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .about-text {
        background-color: #013d7c;
        color: #fff;
        font-family: Bogle;
        font-size: 1rem;
        padding: 10px;
    }
}

@media (max-width: 768px) { 
    .landing {
        display: flex;
        flex-direction: column;
    }
    .landing h1 {
        font-size: 24px
    }
}