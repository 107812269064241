footer {
    padding: 20px;
}
.footer{
    background-color: #013d7c !important;
    z-index: 70;
    font-size: 18px;
    line-height: 24px;
    color : #ffff;
    left: 0;
    width: 100%;
    color: white;
    border-radius: 10px;
    font-family: Bogle;
}

.service{
    font-size: 22px;
}
.service-name,.development-name{
    line-height: 28px;
    padding: 2px 0;
    line-height: 28px;
    padding: 2px 0;
}
.service-name:not(:last-child){
    border-bottom: 1px dashed #ffffff1a;
}
.development-name:not(:last-child){
    border-bottom: 1px dashed #ffffff1a;
}
.list-elements{
    list-style-type: none;
    padding-left: 0;
}
.contact-image{
    border: 2px solid #d1103f;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 34px;
    margin-right: 10px;
}
.contact-section{
    padding: 5px 0;
    margin-top: 10px;
}
.copyright-text{
    margin: 5px 0 10px;
    color: #ffffff;
    text-align: center;
    padding: 10px;
}
.footer-container{
    margin: 0 45px;
    padding-top: 38px;
}